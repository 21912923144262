import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	IPageWillMountHandler,
	FeatureStateSymbol,
	CurrentRouteInfoSymbol,
	PageFeatureConfigSymbol,
} from '@wix/thunderbolt-symbols'
import { ISeoSiteApi, SeoPageConfig } from './types'
import { name, SeoSiteSymbol } from './symbols'
import { IFeatureState } from 'thunderbolt-feature-state'
import { ICurrentRouteInfo, IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'

type SeoFeatureState = {
	didNavigate: boolean
}
declare global {
	interface Window {
		clientSideRender: boolean
	}
}

const initialState: SeoFeatureState = {
	didNavigate: false,
}

const shouldLoad = ({ didNavigate: isAfterNavigation }: SeoFeatureState): boolean => {
	const isInSSR = !process.env.browser
	const isClientFallback = !isInSSR && window.clientSideRender
	const noTitleExistsOnClient = !isInSSR && !document?.title?.length && !!document?.body
	return isInSSR || isAfterNavigation || isClientFallback || noTitleExistsOnClient
}

const seoPageFactory = (
	pageLevelSeoData: SeoPageConfig,
	featureState: IFeatureState<SeoFeatureState>,
	seoApi: ISeoSiteApi,
	routeApi: ICurrentRouteInfo,
	urlHistoryManager: IUrlHistoryManager
): IPageWillMountHandler => {
	return {
		pageWillMount: async (pageId) => {
			const state = featureState.get() || initialState
			const url = urlHistoryManager.getParsedUrl()
			seoApi.setPageHref(url.href)
			seoApi.setPageId(pageId)
			seoApi.setPageData(pageLevelSeoData)
			seoApi.resetTpaAndCorvidData()
			const routeInfo = routeApi.getCurrentRouteInfo()
			routeInfo?.dynamicRouteData && (await seoApi.setDynamicRouteOverrides(routeInfo.dynamicRouteData))
			if (!shouldLoad(state)) {
				featureState.update(() => ({ didNavigate: true }))
				return
			}
			seoApi.renderSEO()
		},
	}
}

export const SeoPage = withDependencies(
	[
		named(PageFeatureConfigSymbol, name),
		named(FeatureStateSymbol, name),
		SeoSiteSymbol,
		CurrentRouteInfoSymbol,
		UrlHistoryManagerSymbol,
	],
	seoPageFactory
)
