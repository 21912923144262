import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	WixBiSessionSymbol,
	FeatureStateSymbol,
	SiteFeatureConfigSymbol,
	BusinessLoggerSymbol,
} from '@wix/thunderbolt-symbols'
import { ConsentPolicySymbol } from 'feature-consent-policy'
import { PageNumberSymbol } from 'feature-business-logger'
import { UrlHistoryManagerSymbol } from 'feature-router'
import { IReporterInit, ReporterState } from '../types'
import { ReporterSymbol, name } from '../symbols'
import { setState } from '../utils'
import { init } from './init'

const initialState: Partial<ReporterState> = {
	isAdapterInitialized: false,
	pageNumber: 1,
}

const reporterInit: IReporterInit = (
	reporterApi,
	featureState,
	siteConfig,
	wixBiSession,
	businessLogger,
	consentPolicy,
	urlHistoryManager,
	pageNumberApi
) => ({
	appWillMount() {
		setState(featureState, initialState)

		const shouldInitReporter = !wixBiSession.suppressbi
		if (shouldInitReporter) {
			init(
				reporterApi,
				siteConfig,
				wixBiSession,
				businessLogger,
				featureState,
				consentPolicy,
				urlHistoryManager,
				pageNumberApi
			)
		}
	},
})

export const ReporterInit = withDependencies(
	[
		ReporterSymbol,
		named(FeatureStateSymbol, name),
		named(SiteFeatureConfigSymbol, name),
		WixBiSessionSymbol,
		BusinessLoggerSymbol,
		ConsentPolicySymbol,
		UrlHistoryManagerSymbol,
		PageNumberSymbol,
	],
	reporterInit
)
