import {
	IAppWillMountHandler,
	WixBiSession,
	BusinessLogger,
	ReporterEvent,
	IReporterApi,
} from '@wix/thunderbolt-symbols'
import { IFeatureState } from 'thunderbolt-feature-state'
import { IConsentPolicy } from 'feature-consent-policy'
import { IPageNumber } from 'feature-business-logger'
import { IUrlHistoryManager } from 'feature-router'

export type ReporterSiteConfig = {
	userId: string
	metaSiteId: string
	isPremium: boolean
	isViewerMode: boolean
	isFBServerEventsAppProvisioned: boolean
}

export { ReporterEvent, IReporterApi }

export interface ReporterState {
	isAdapterInitialized: boolean
	didPreventEvents: boolean
	pageNumber: number
	pageUrl: URL
	pageId: string
}

export type IReporterInit = (
	reporterApi: IReporterApi,
	featureState: IFeatureState<ReporterState>,
	siteConfig: ReporterSiteConfig,
	wixBiSession: WixBiSession,
	businessLogger: BusinessLogger,
	consentPolicy: IConsentPolicy,
	urlHistoryManager: IUrlHistoryManager,
	pageNumberApi: IPageNumber,
	pageId: string
) => IAppWillMountHandler

declare global {
	interface Window {
		promoteAnalyticsChannels?: Array<any>
		fbq?: Function
		ga?: Function
		dataLayer?: Array<any>
		ym?: { hit: Function }
	}
}

export enum PiiParams {
	buyerMail = 'buyerMail',
	buyerId = 'buyerId',
}
