import { IReporterOptions } from '@wix/thunderbolt-symbols'
import { IReporterApi } from '.'

export function reportPageView(
	reporterApi: IReporterApi,
	parsedUrl: URL,
	pageNumber: number,
	pageId: string = '',
	reporterOptions: IReporterOptions = {}
): void | undefined {
	if (pageId === 'masterPage') {
		return
	}

	const pageData = {
		pagePath: parsedUrl.pathname.concat(parsedUrl.search),
		pageTitle: window.document.title,
		pageId,
		pageNumber,
	}

	reporterApi.trackEvent(
		{
			eventName: 'PageView',
			params: pageData,
			options: { reportToManagedChannels: true, context: { isFirstVisit: pageNumber === 1 } },
		},
		reporterOptions
	)
}
