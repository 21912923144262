import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { LogicalReflectorSymbol, PageProviderSymbol, PageStructureJsonSymbol, PagePropsJsonSymbol } from './symbols'
import { IPageReflector, IPageProvider } from './types'
import { PageProvider } from './PageReflector'
import { LogicalReflector } from './logicalReflector'

export const site: ContainerModuleLoader = (bind) => {
	bind<IPageProvider>(PageProviderSymbol).toProvider<IPageReflector>(PageProvider)
	bind(LogicalReflectorSymbol).toProvider<IPageReflector>(LogicalReflector)
}

export {
	PageProviderSymbol,
	LogicalReflectorSymbol,
	PageStructureJsonSymbol,
	PagePropsJsonSymbol,
	IPageProvider,
	IPageReflector,
}
