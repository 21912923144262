import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { IReporterApi, ReporterSiteConfig, ReporterState } from './types'
import { IFeatureState } from 'thunderbolt-feature-state'
import { SessionManagerSymbol, ISessionManager } from 'feature-session-manager'

import { name } from './symbols'
import { FeatureStateSymbol, SiteFeatureConfigSymbol, ExperimentsSymbol, Experiments } from '@wix/thunderbolt-symbols'
import { setState } from './utils'
import { enrichEventOptions } from './event-options'
import { resolveEventParams } from './resolve-event-params'

const reporterFactory = (
	featureState: IFeatureState<ReporterState>,
	siteConfig: ReporterSiteConfig,
	experiments: Experiments,
	sessionManager: ISessionManager
): IReporterApi => ({
	trackEvent: async (event, { reportToChannelsOnly } = {}) => {
		const { isAdapterInitialized } = featureState.get() || {}
		const { eventName, params = {}, options = {} } = event
		const reportBothListenersAndChannels = !reportToChannelsOnly
		const eventParams = resolveEventParams(params as Record<string, string>, sessionManager)
		const eventOptions = enrichEventOptions(options, experiments, siteConfig)
		const api = await import('./api' /* webpackChunkName: "reporter-api" */)

		if (api.isNewAnalyticsAdapterAPI()) {
			if (isAdapterInitialized && reportBothListenersAndChannels) {
				api.trackEvent(eventName, eventParams, eventOptions)
			}
			if (isAdapterInitialized && reportToChannelsOnly) {
				api.trackEventToChannelsOnly(eventName, eventParams, eventOptions)
			}
			if (!isAdapterInitialized) {
				setState(featureState, { didPreventEvents: true })
				if (reportBothListenersAndChannels) {
					api.trackEventToListenersOnly(eventName, eventParams, eventOptions)
				}
			}
		} else {
			if (!reportToChannelsOnly) {
				api.trackListenerEvent(eventName, eventParams, eventOptions)
			}
			if (isAdapterInitialized) {
				api.trackEvent(eventName, eventParams, eventOptions)
			} else {
				setState(featureState, { didPreventEvents: true })
			}
		}
	},
})

export const Reporter = withDependencies(
	[named(FeatureStateSymbol, name), named(SiteFeatureConfigSymbol, name), ExperimentsSymbol, SessionManagerSymbol],
	reporterFactory
)
