import { withDependencies } from '@wix/thunderbolt-ioc'
import { IPageDidMountHandler, pageIdSym } from '@wix/thunderbolt-symbols'
import { IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'
import { IReporterApi } from '../types'
import { ReporterSymbol } from '../symbols'
import { reportPageView } from '../report-page-view'
import { IPageNumber, PageNumberSymbol } from 'feature-business-logger'

const reporterNavigationHandlerFactory = (
	reporterApi: IReporterApi,
	urlHistoryManager: IUrlHistoryManager,
	pageNumberApi: IPageNumber,
	pageId: string
): IPageDidMountHandler => ({
	pageDidMount: () => {
		const pageUrl = urlHistoryManager.getParsedUrl()
		const pageNumber = pageNumberApi.getPageNumber()
		reportPageView(reporterApi, pageUrl, pageNumber, pageId)
	},
})

export const ReporterNavigationHandler = withDependencies(
	[ReporterSymbol, UrlHistoryManagerSymbol, PageNumberSymbol, pageIdSym],
	reporterNavigationHandlerFactory
)
